import React from 'react';
import Layout from '../components/Layout';
import Module from '../components/Module';

export default () => (
  <Layout pageTitle="Account Settings for Miter Accounts" description="Settings for your Miter account">
    <Module>
      <h1>Account</h1>
      <div className="BodyHuge">
        Someday, there will be settings for your Miter account, including to manage any paid services. Today, that
        doesn't exist.
      </div>
    </Module>
  </Layout>
);
